import { useSearchParams } from "react-router-dom";

const AlreadyConnectedWallet = () => {
  const [searchParams] = useSearchParams();
  const telegramId = searchParams.get("telegramId") || "";
  const discordId = searchParams.get("discordId") || "";
  const telegram = searchParams.get("telegram") || "";
  const configurationId = searchParams.get("configurationId") || "";
  const errorMessage = localStorage.getItem("errorMessageOfParticipantAPI");

  return (
    <div className="h-screen px-8 lg:px-16 py-11 overflow-hidden bg-white">
      <div className="flex justify-end w-full">
        <img src="/assets/linkko.svg" alt="linkko" />
      </div>
      <div className="flex justify-center items-center w-full h-full">
        <div className="flex flex-col justify-center items-center max-w-xl">
          {telegram || telegramId ? (
            <img src="/assets/wallet-connect.svg" alt="connect" />
          ) : (
            <img src="/assets/wallet-connect-discod.svg" alt="connect" />
          )}

          <span className="text-2xl md:text-4xl font-bold text-black-100 text-center mt-12 mb-8">
            Something went wrong, please check the error message and try again
          </span>
          <div className="rounded-full py-3 lg:px-8 px-4 bg-yellow-100 text-yellow-200 text-center text-base">
            <span>{errorMessage}</span>
          </div>
          <div className="flex flex-col gap-6 mt-12">
            <a
              className="flex items-center justify-center text-white text-sm gap-3 font-bold bg-primary-gradient py-4 px-16 rounded-2xl"
              href={`/?${telegramId ? "telegramId=" : "discordId="}${
                telegramId ? telegramId : discordId
              }&configurationId=${configurationId}`}
            >
              Try again
              <img src="/assets/arrow.svg" alt="link" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AlreadyConnectedWallet;
