import React, { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Header from "./common/header";
import { auth } from "../firebase";
import { useDispatch } from "react-redux";
import { doConnect } from "../redux/blockchain/blockchainActions";

const Login = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [loading, setIsLoading] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [searchParams] = useSearchParams();
  const telegramId = searchParams.get("telegramId") || "";
  const discordId = searchParams.get("discordId") || "";
  const configurationId = searchParams.get("configurationId") || "";
  const page = searchParams.get("page") || "";
  const connectionName = "metamask";
  const navigate = useNavigate();

  const handleErrorTimeOut = (message) => {
    setError(message);
    setTimeout(() => {
      setError(null);
    }, 5000);
  };

  const handleLogin = async () => {
    try {
      setIsLoading(true);
      await auth
        .signInWithEmailAndPassword(email, password)
        .then((userCredential) => {
          const uid = userCredential?.user?.uid;
          if (uid) {
            dispatch(
              doConnect(
                configurationId,
                telegramId,
                discordId,
                uid,
                navigate,
                connectionName,
                page,
                uid
              )
            );
          }
          setIsLoading(false);
        });
    } catch (error) {
      handleErrorTimeOut(error.message);
      setIsLoading(false);
    }
  };

  return (
    <div className="xl:h-screen px-8 lg:px-16 py-8 lg:py-11  overflow-hidden bg-red-500">
      <Header />
      <div className="flex flex-col gap-12 justify-center h-full items-center w-full mt-14 lg:mt-0">
        {/* <div className="flex flex-col lg:flex-row justify-evenly lg:w-2/5 items-center mt-16 lg:mt-0 gap-8 lg:gap-2">
          <span className="text-2xl font-bold whitespace-nowrap">
            Sign In to Linkko
          </span>
          <button
            className="flex items-center justify-center border border-blue-200 gap-3 text-sm font-bold py-4 px-9 rounded-full w-max"
            style={{ border: "1px solid blue" }}
          >
            <img src="/assets/google.svg" alt="google" />
            <span className="bg-clip-text bg-primary-gradient text-transparent whitespace-nowrap">
              Login with Google
            </span>
          </button>
        </div> */}
        {/* <div className="flex items-center gap-6 text-gray-100 w-full lg:w-2/5">
          <div className="w-full border-t" />
          or <div className="w-full border-t" />
        </div> */}

        <div className="flex flex-col lg:w-2/5 gap-6 lg:gap-10 w-full">
          <div className="flex flex-col gap-4">
            <label className="font-medium text-black-100">Username</label>
            <input
              className="border h-12 w-full rounded-lg py-3 px-6 text-sm"
              placeholder="Enter Your Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="flex relative flex-col w-full gap-4">
            <label className="font-medium text-black-100">Password</label>
            <input
              type={passwordShown ? "text" : "password"}
              className="border h-12 w-full rounded-lg py-3 px-6 text-sm"
              placeholder="Enter Your Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <img
              src={passwordShown ? "/assets/eye.svg" : "/assets/eye-slash.svg"}
              alt="show/hide"
              className="absolute cursor-pointer bottom-12 right-4 w-6"
              onClick={() => setPasswordShown(!passwordShown)}
            />
            <Link
              to="/forgot-password"
              className="font-medium text-sm text-primary-blue"
            >
              Forgot Password?
            </Link>
          </div>
          {error && (
            <span className="flex gap-2 text-sm font-medium bg-red-100 text-red-200 p-4 rounded-2xl">
              <img src="/assets/key.svg" alt="err" />
              {error} Try again!
            </span>
          )}
          <button
            className={`flex justify-center items-center md:bg-primary-blue bg-primary-gradient border-none w-full md:h-auto h-12 py-4 px-9 rounded-full text-white font-bold ${
              loading && "opacity-40 cursor-default"
            }`}
            onClick={handleLogin}
            type="submit"
            disabled={loading}
          >
            Login
          </button>

          <div className="flex flex-col items-center">
            <div className="font-medium text-sm text-gray-100">
              <span>
                Already have an account?
                <Link to="/login" className="text-primary-blue">
                  {" "}
                  Login Here
                </Link>
              </span>
            </div>
            <div className="font-medium text-sm text-gray-100 mt-4">
              <span>
                Are you an admin?&nbsp;
                <Link to="/login" className="text-primary-blue">
                  Create a Configuration Here
                </Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
