import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const SaveWallet = () => {
  const { signup, resetPassword } = useAuth();

  const points = [
    "Easy one-click access to gated content",
    "100% wallet protection with read-only access",
    "No need to constantly reconnect your wallet",
    "Manage assets from multiple wallets with ease",
  ];
  const [emailField, setEmailField] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const [error, setError] = useState("");

  const telegram = searchParams.get("telegram") || "";
  let participantType = telegram ? "telegram" : "discord";
  const navigate = useNavigate();
  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(emailPattern.test(email));
  };
  const handleSubmit = async () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    function generateString(length) {
      let result = "";
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    }

    const password = generateString(8);
    try {
      setError("");
      setLoading(true);
      await signup(emailField, password);
      localStorage.setItem("email", emailField);
      localStorage.setItem("password", password);
      await resetPassword(emailField);
      navigate(`/verify-email?${participantType}=true`);
    } catch (error) {
      navigate(`/connect-linkko-account?${participantType}=true&isExist=true`);
      setError(`Error creating user and sending emails: ${error?.message}`);
    }
    setLoading(false);
  };

  return (
    <div className="lg:h-screen px-8 lg:px-16 py-11 overflow-hidden bg-white">
      <div className="flex justify-center lg:justify-end w-full">
        <img src="/assets/linkko.svg" alt="linkko" />
      </div>

      <div className="flex gap-20 justify-center h-full items-start md:pt-16">
        <div className="hidden lg:flex items-center justify-center w-1/2 mt-16">
          <img src="/assets/vectors/save-wallet.svg" alt="saved" />
        </div>
        <div className="flex flex-col gap-10">
          <div className="flex items-center flex-col lg:justify-start gap-4 lg:w-max w-full">
            {/* <div className="flex flex-col md:flex-row items-center justify-center gap-4 rounded-3xl py-4 lg:px-6 px-10 w-max bg-blue-200 text-blue-600">
              <div className="flex flex-row gap-4">
                <img src="/assets/check-blue.svg" alt="succes" />
                <span>Wallet Connected</span>
              </div>
            </div> */}
          </div>
          <span className="text-2xl md:text-4xl text-center lg:text-left text-black-100 font-semibold">
            Join Linkko for instant access to exclusive content! 🚀
          </span>
          <div className="block lg:hidden mx-auto">
            <img src="/assets/vectors/save-wallet.svg" alt="saved" />
          </div>
          <div>
            <div className="flex flex-col lg:flex-row gap-4 md:gap-6 justify-center lg:justify-start">
              <div className="flex border rounded-2xl px-5 py-4 lg:w-3/5 items-center gap-3">
                <img src="/assets/sms.svg" alt="email" className="w-5 h-5" />
                <input
                  required
                  placeholder="Enter Your Email"
                  className="w-full text-sm focus:outline-none focus:border-none"
                  type="email"
                  value={emailField}
                  onChange={(e) => {
                    setEmailField(e.target.value);
                    validateEmail(e.target.value);
                  }}
                />
              </div>

              <button
                className={`flex justify-center items-center md:bg-primary-blue bg-primary-gradient border-none py-4 px-9 rounded-2xl text-white font-bold whitespace-nowrap ${
                  loading ? "opacity-40" : ""
                } `}
                disabled={!isValidEmail || emailField === "" || loading}
                type="submit"
                onClick={handleSubmit}
              >
                Finalise Linkko Account
              </button>
            </div>
            {isValidEmail ? null : (
              <span className="text-red-200 text-sm">
                Invalid Email Address
              </span>
            )}
            {error && (
              <span className="flex gap-2 text-sm font-medium bg-red-100 text-red-200 p-4 rounded-2xl mt-4 w-fit">
                <img src="/assets/key.svg" alt="err" />
                {error}
              </span>
            )}
          </div>

          <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-4">
            {points.map((point) => (
              <li className="flex items-center gap-2" key={point}>
                <img src="/assets/check.svg" alt="point" />
                <span className="text-sm text-gray-100 font-medium">
                  {point}
                </span>
              </li>
            ))}
          </ul>
          {telegram ? (
            <a href={`https://t.me/${process.env.REACT_APP_TG_BOT_USERNAME}`}>
              <div className="bg-primary-gradient p-1px rounded-2xl lg:w-fit">
                <button className="flex gap-2 items-center justify-center bg-white text-sm  font-bold py-4 px-9 rounded-2xl w-full">
                  <span className="bg-clip-text bg-primary-gradient text-transparent h-full">
                    Back to Telegram
                  </span>
                  <img src="/assets/arrow-up.svg" alt="arrow blue" />
                </button>
              </div>
            </a>
          ) : (
            <a href="https://discord.com/app">
              <div className="bg-primary-gradient p-1px rounded-2xl lg:w-fit">
                <button className="flex gap-2 items-center justify-center bg-white text-sm font-bold py-4 px-9 rounded-2xl w-full">
                  <span className="bg-clip-text bg-primary-gradient text-transparent h-full">
                    Back to Discord
                  </span>
                  <img src="/assets/arrow-up.svg" alt="arrow blue" />
                </button>
              </div>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default SaveWallet;
