import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex justify-between items-center w-full">
        <span
          onClick={() => navigate(-1)}
          className="flex gap-4 items-center text-blue-200 cursor-pointer"
        >
          <img src="/assets/caret-left.svg" alt="linkko" />
          Back
        </span>
        {/* <p className="items-center text-gray-100 self-end lg:flex hidden">
          <img className="h-5 w-5 mr-4" src="/assets/loader.svg" alt="site" />
          Accessing the page{" "}
          <span className="text-black-100 italic font-bold">
            "{window.location.origin}"
          </span>
        </p> */}
        <img src="/assets/linkko.svg" alt="linkko" />
      </div>
      {/* <div className="flex justify-center mt-16 lg:hidden">
        <div className="text-gray-100 self-end">
          <img
            className="h-5 w-5 mx-auto mb-4"
            src="/assets/loader.svg"
            alt="site"
          />
          <div className="flex flex-col items-center">
            <span>Accessing the page</span>
            <span className="text-black-100 italic font-bold">
              "{window.location.origin}"
            </span>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Header;
