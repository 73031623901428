import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { useState } from "react";

const VerifyEmail = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const telegram = searchParams.get("telegram") || "";
  const email = localStorage.getItem("email");
  const password = localStorage.getItem("password");
  const [isVarified, setIsVarified] = useState(true);
  const handleVerified = async () => {
    const res = await login(email, password);

    setIsVarified(false);
    if (res) {
      setIsVarified(true);
      navigate("/connect-linkko-account");
    }
  };

  return (
    <>
      <div className="lg:h-screen px-8 lg:px-16 py-11 overflow-hidden bg-white">
        <div className="flex justify-center lg:justify-end w-full">
          <img src="/assets/linkko.svg" alt="linkko" />
        </div>
        <div className="flex gap-20 justify-center h-full items-start lg:mt-16 mt-4">
          <div className="hidden lg:flex items-center justify-center w-1/2 mt-16">
            <img src="/assets/vectors/save-wallet.svg" alt="saved" />
          </div>
          <div className="flex flex-col gap-6 lg:gap-10 lg:mt-16">
            <div className="flex items-center flex-col  lg:justify-start gap-4 lg:w-max w-full">
              {isVarified ? (
                <div className="flex items-center flex-col lg:justify-start gap-4 lg:w-max w-full">
                  <div className="flex flex-col md:flex-row items-center justify-center gap-4 rounded-3xl py-4 lg:px-6 px-10 w-max bg-green-100 text-green-200">
                    <div className="flex flex-row gap-4">
                      <img src="/assets/check-green.svg" alt="succes" />
                      <span>Account Created</span>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex items-center justify-center gap-4 rounded-full py-4 lg:px-24 px-10 w-max bg-red-100 text-red-200">
                  <span>Email is not verified</span>
                </div>
              )}
            </div>
            <span className="text-2xl md:text-4xl text-center lg:text-left text-black-100 font-semibold">
              Account created, please verify your email address
            </span>
            <div className="block lg:hidden mx-auto">
              <img src="/assets/vectors/save-wallet.svg" alt="saved" />
            </div>
            <div className="flex flex-col lg:flex-row gap-6">
              <button
                className="flex justify-center items-center md:bg-primary-blue bg-primary-gradient border-none py-4 px-10 rounded-2xl  text-white font-bold whitespace-nowrap lg:w-fit"
                type="submit"
                onClick={handleVerified}
              >
                Login to Linkko
              </button>
              {telegram ? (
                <a
                  href={`https://t.me/${process.env.REACT_APP_TG_BOT_USERNAME}`}
                >
                  <div className="bg-primary-gradient p-1px rounded-2xl lg:w-fit">
                    <button className="flex gap-2 items-center justify-center bg-white text-sm font-bold py-4 px-9 rounded-2xl w-full">
                      <span className="bg-clip-text bg-primary-gradient text-transparent h-full">
                        Back to Telegram
                      </span>
                      <img src="/assets/arrow-up.svg" alt="arrow blue" />
                    </button>
                  </div>
                </a>
              ) : (
                <a href="https://discord.com/app">
                  <div className="bg-primary-gradient p-1px rounded-2xl lg:w-fit">
                    <button className="flex gap-2 items-center justify-center bg-white text-sm font-bold py-4 px-9 rounded-2xl w-full">
                      <span className="bg-clip-text bg-primary-gradient text-transparent h-full">
                        Back to Discord
                      </span>
                      <img src="/assets/arrow-up.svg" alt="arrow blue" />
                    </button>
                  </div>
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default VerifyEmail;
