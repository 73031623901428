import React from "react";
import Header from "./common/header";
import { doConnect, doSign } from "../redux/blockchain/blockchainActions";
import { useDispatch } from "react-redux";

import { useSearchParams, useNavigate } from "react-router-dom";
import { useAccount, useConnect, useDisconnect } from "wagmi";
import { useSignMessage } from "wagmi";

const WalletConnector = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const telegramId = searchParams.get("telegramId") || "";
  const discordId = searchParams.get("discordId") || "";
  const configurationId = searchParams.get("configurationId") || "";

  const connect = useConnect();
  const disconnect = useDisconnect();
  const account = useAccount();
  const sign = useSignMessage();
  const navigate = useNavigate();
  localStorage.setItem(
    "wallet_name",
    account?.connector?.name.toLocaleLowerCase()
  );

  return (
    <div className="lg:h-screen px-8 lg:px-16 lg:py-11 py-6 relative lg:overflow-hidden bg-white">
      <Header />
      <div className="flex flex-col gap-10 justify-center h-auto lg:h-full items-center w-full">
        <div className="flex bg-yellow-100 text-yellow-200 py-3 px-4 rounded-full gap-4 text-sm mt-14 lg:mt-0">
          <img src="/assets/info-circle.svg" alt="info" />
          <span>
            Avoid scams, check that this URL starts with connect.linkko.io
            before connecting your wallet
          </span>
        </div>

        {connect.isError && (
          <div>
            <span className="flex gap-2 text-sm font-medium bg-red-100 text-red-200 p-4 rounded-2xl">
              <img src="/assets/key.svg" alt="err" />
              {connect.error.message}
            </span>
          </div>
        )}

        {sign.isError && (
          <div>
            <span className="flex gap-2 text-sm font-medium bg-red-100 text-red-200 p-4 rounded-2xl">
              <img src="/assets/key.svg" alt="err" />
              {sign.error.message}
            </span>
          </div>
        )}

        <div className="bg-primary-gradient p-2px rounded-3xl">
          <div className="flex flex-col lg:flex-row relative items-center gap-10 lg:gap-20 bg-white rounded-3xl p-4 lg:p-12 z-20">
            {!!account.connector ? (
              <div>
                <div className="flex items-center justify-center rounded-2xl py-4 px-6 bg-green-100 text-green-200">
                  Connected to {account.connector.name}
                </div>
                <button
                  className="mt-4 flex items-center justify-center w-full text-white text-sm gap-3 font-bold bg-primary-gradient py-4 px-9 rounded-2xl"
                  onClick={disconnect.disconnect}
                >
                  Disconnect
                </button>
              </div>
            ) : (
              <button className="flex items-center justify-center bg-white text-base font-bold rounded-2xl">
                <span className="bg-clip-text bg-primary-gradient text-transparent h-full w-full cursor-default">
                  Wallet not connected.
                </span>
              </button>
            )}
            {!!sign.isSuccess && (
              <div className="flex clear-both">Signing complete!</div>
            )}
          </div>
        </div>

        <div className="bg-primary-gradient p-2px rounded-3xl">
          <div className="flex flex-col md:flex-row gap-10 lg:gap-20 bg-white rounded-3xl p-4 lg:p-12 z-20">
            {connect.connectors.map((connector) =>
              !!connector ? (
                <button
                  className="flex flex-col items-center justify-center gap-2 hover:bg-blue-50 p-4 lg:p-12 transition-all duration-200 ease-in-out rounded-3xl"
                  disabled={!connector.ready}
                  key={connector.id}
                  onClick={() => {
                    connect.connect({ connector });
                  }}
                >
                  <img
                    src={`/assets/${connector.name}.svg`}
                    alt=""
                    className="lg:w-20 w-14 lg:h-20 h-14"
                  />{" "}
                  {connector.name} {!connector.ready && " (unsupported)"}
                  {connect.isLoading &&
                    connector.id === connect.pendingConnector?.id &&
                    " (connecting)"}
                </button>
              ) : (
                <></>
              )
            )}
          </div>
        </div>
      </div>

      {connect.isSuccess &&
        !sign.isSuccess &&
        dispatch(doSign(account, sign)) && <></>}

      {sign.isSuccess &&
        dispatch(
          doConnect(
            configurationId,
            telegramId,
            discordId,
            null,
            navigate,
            account,
            sign
          )
        ) && <></>}
    </div>
  );
};

export default WalletConnector;
