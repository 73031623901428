import { WagmiConfig, configureChains, createConfig } from "wagmi";
import { mainnet, polygon } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";
import "@rainbow-me/rainbowkit/styles.css";
import { getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { Route, Routes } from "react-router-dom";
import { infuraProvider } from "wagmi/providers/infura";
import Login from "./components/login";
import SaveWallet from "./components/save-wallet";
import Start from "./components/start";
import WalletConnector from "./components/wallet-connector";
import VerifyEmail from "./components/verify-email";
import ConnectLinkkoAccount from "./components/connect-linkko-account";
import { AuthProvider } from "./context/AuthContext";
import GatedPage from "./components/GatedPage";
import AlreadyConnectedWallet from "./components/already-connected-wallet";

const { chains, publicClient } = configureChains(
  [mainnet, polygon],
  [
    infuraProvider({ apiKey: process.env.REACT_APP_INFURA_ID }),
    publicProvider(),
  ]
);

const { connectors } = getDefaultWallets({
  appName: "My RainbowKit App",
  projectId: process.env.REACT_APP_WALLETCONNECT_PROJECT_ID,
  chains,
});

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
});

const App = () => {
  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider modalSize="compact" chains={chains}>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<GatedPage />} />
            <Route path="/success" element={<Start />} />
            <Route
              path="/wallet-already-connected"
              element={<AlreadyConnectedWallet />}
            />
            <Route path="/wallet-connect" element={<WalletConnector />} />
            <Route path="/login" element={<Login />} />
            <Route path="/save-wallet" element={<SaveWallet />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route
              path="/connect-linkko-account"
              element={<ConnectLinkkoAccount />}
            />
          </Routes>
        </AuthProvider>
      </RainbowKitProvider>
    </WagmiConfig>
  );
};

export default App;
