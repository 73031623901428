import React, { useContext, useState, useEffect } from "react";
import { auth } from "../firebase";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  const [inputField, setInputField] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });

  async function signup(email, password) {
    const userCredential = await auth.createUserWithEmailAndPassword(
      email,
      password
    );
    userCredential.user.sendEmailVerification();
    auth.signOut();

    return userCredential;
  }

  async function login(email, password) {
    const userCredential = await auth.signInWithEmailAndPassword(
      email,
      password
    );
    if (!userCredential?.user?.emailVerified) {
      await logout();
      return false;
    }

    return true;
  }

  function logout() {
    return auth.signOut();
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email);
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password);
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
    inputField,
    setInputField,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
