// const connectRequest = () => {
//   return {
//     type: "CONNECTION_REQUEST",
//   };
// };

const connectSuccess = (payload) => {
  return {
    type: "CONNECTION_SUCCESS",
    payload: payload,
  };
};

const connectFailed = (payload) => {
  return {
    type: "CONNECTION_FAILED",
    payload: payload,
  };
};

// const updateAccountRequest = (payload) => {
//   return {
//     type: "UPDATE_ACCOUNT",
//     payload: payload,
//   };
// };

const getNonce = async (account) => {
  let response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/get_nonce`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ identifier: account.address }),
  });
  const { success, message, data } = await response.json();
  if (!success) {
    throw new Error(`Failed to get nonce: ${message}`);
  }
  return data;
};

export const connectParticipant = async (
  participantType,
  participantId,
  configurationId,
  walletAddress,
  signature,
  uid,
  navigate
) => {
  let requestBody;
  if (uid) {
    requestBody = {
      participantId: participantId,
      configurationId: configurationId,
      uid: uid,
    };
  } else {
    requestBody = {
      participantId: participantId,
      configurationId: configurationId,
      wallet_address: walletAddress,
      signature: signature,
    };
  }
  //To be call API connect_participants and add_wallet

  localStorage.setItem("wallet_address", walletAddress);
  localStorage.setItem("signature", signature);
  localStorage.setItem("participantId", participantId);
  localStorage.setItem("configurationId", configurationId);
  localStorage.setItem("participantType", participantType);

  let url = `${process.env.REACT_APP_BACKEND_URL}/connect_${participantType}_participant`;
  let response = await fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(requestBody),
  });
  const { success, message } = await response.json();
  if (success) {
    if (uid) {
      navigate(`/success/?${participantType}=true`);
    } else {
      navigate(`/save-wallet?${participantType}=true`);
    }
  } else if (
    success === false &&
    message === "This wallet is already connected to access this content!"
  ) {
    localStorage.setItem("errorMessageOfParticipantAPI", message);
    navigate(
      `/wallet-already-connected/?${
        participantType === "telegram" ? "telegramId=" : "discordId="
      }${participantId}&configurationId=${configurationId}`
    );
  } else {
    throw new Error(message);
  }
};

export const doSign = (account, sign) => {
  return async (dispatch) => {
    localStorage.clear();
    try {
      if (sign.isIdle) {
        let message = await getNonce(account);
        sign.signMessage({ message });
      }
    } catch (err) {
      console.error(err);
      dispatch(connectFailed(`Something went wrong: ${err.message}`));
    }
  };
};

export const doConnect = (
  configurationId,
  telegramId,
  discordId,
  uid,
  navigate,
  account,
  sign
) => {
  return async (dispatch) => {
    try {
      if (!!discordId) {
        connectParticipant(
          "discord",
          discordId,
          configurationId,
          account.address,
          sign.data,
          uid,
          navigate
        );
      } else if (!!telegramId) {
        connectParticipant(
          "telegram",
          telegramId,
          configurationId,
          account.address,
          sign.data,
          uid,
          navigate
        );
      } else {
        throw new Error("No participant ID provided");
      }
      dispatch(connectSuccess({ account: account.address, uid: uid }));
    } catch (err) {
      console.error(err);
      dispatch(connectFailed(`Something went wrong: ${err.message}`));
    }
  };
};
