import React from "react";
import { useSearchParams } from "react-router-dom";

const Start = () => {
  const [searchParams] = useSearchParams();
  const telegramId = searchParams.get("telegramId") || "";
  const telegram = searchParams.get("telegram") || "";

  return (
    <div className="h-screen px-8 lg:px-16 py-11 overflow-hidden bg-white">
      <div className="flex justify-end w-full">
        <img src="/assets/linkko.svg" alt="linkko" />
      </div>
      <div className="flex justify-center items-center w-full h-full">
        <div className="flex flex-col gap-12 justify-center items-center max-w-lg">
          {telegram || telegramId ? (
            <img src="/assets/wallet-connect.svg" alt="connect" />
          ) : (
            <img src="/assets/wallet-connect-discod.svg" alt="connect" />
          )}
          <span className="text-2xl md:text-3xl font-bold text-black-100 text-center">
            {`Wallet connected! Go back on ${
              telegram || telegramId ? "Telegram" : "Discord"
            } and click on “Confirm”`}
          </span>

          <div className="flex flex-col gap-6">
            {telegram ? (
              <a
                className="flex items-center justify-center text-white text-sm gap-3 font-bold bg-primary-gradient py-4 px-9 rounded-2xl"
                href={`https://t.me/${process.env.REACT_APP_TG_BOT_USERNAME}`}
              >
                Back to Telegram
                <img src="/assets/arrow.svg" alt="link" />
              </a>
            ) : (
              <a
                className="flex items-center justify-center text-white text-sm gap-3 font-bold bg-primary-gradient py-4 px-4 rounded-2xl"
                href="https://discord.com/app"
              >
                Back to Discord
                <img src="/assets/arrow.svg" alt="link" />
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Start;
