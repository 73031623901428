import { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { auth } from "../firebase";

const ConnectLinkkoAccount = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [error, setError] = useState("");
  const { currentUser, logout } = useAuth();
  // const wallet_name = localStorage.getItem("wallet_name");
  const wallet_address = localStorage.getItem("wallet_address");
  const signature = localStorage.getItem("signature");
  // const configurationId = localStorage.getItem("configurationId");
  // const participantId = localStorage.getItem("participantId");
  const participantType = localStorage.getItem("participantType");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isExist = searchParams.get("isExist") || "";

  const addWallet = async () => {
    await fetch(`${process.env.REACT_APP_BACKEND_URL}/add_wallet`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify({
        uid: currentUser?.uid,
        email: email,
        wallet_address: wallet_address,
        wallet_name: "Wallet 1",
        signature: signature,
      }),
    });
  };

  const handleSubmit = async () => {
    if (isExist === "true") {
      try {
        await auth
          .signInWithEmailAndPassword(email, password)
          .then((userCredential) => {
            const uid = userCredential?.user?.uid;
            // localStorage.setItem("ExistUID");
            console.log(uid);
            if (uid) {
              fetch(`${process.env.REACT_APP_BACKEND_URL}/add_wallet`, {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                credentials: "same-origin",
                headers: {
                  "Content-Type": "application/json",
                },
                redirect: "follow",
                referrerPolicy: "no-referrer",
                body: JSON.stringify({
                  uid: userCredential?.user?.uid,
                  email: email,
                  wallet_address: wallet_address,
                  wallet_name: "Wallet 1",
                  signature: signature,
                }),
              });
            }
          });

        navigate(`/success/?${participantType}=true`);
        logout();
      } catch (err) {
        console.log(err, "err");
        setError(`Failed to create an account ${err}`);
      }
    } else {
      addWallet();
      navigate(`/success/?${participantType}=true`);
    }
  };
  return (
    <>
      <div className="h-auto overflow-y-auto px-8 lg:px-16 py-11  bg-white">
        <div className="flex justify-center lg:justify-end w-full">
          <img src="/assets/linkko.svg" alt="linkko" />
        </div>
        <div className="flex gap-20 justify-center h-full items-start lg:mt-16 mt-4">
          <div className="hidden lg:flex items-center justify-center w-1/2 mt-16">
            <img src="/assets/vectors/save-wallet.svg" alt="saved" />
          </div>
          <div className="flex flex-col gap-6 lg:gap-10">
            <div className="flex items-center flex-col  lg:justify-start gap-4 lg:w-max w-full">
              {!isExist ? (
                ""
              ) : (
                <div className="flex items-center justify-center gap-4 rounded-full py-4 lg:px-24 px-10 bg-yellow-100 text-yellow-200">
                  <img src="/assets/check-yellow.svg" alt="warn" />
                  <span>Email Already Connected to Linkko Account</span>
                </div>
              )}
            </div>
            <span className="text-2xl md:text-4xl text-center lg:text-left text-black-100 font-semibold">
              Connect to your existing Linkko account to add your wallet
            </span>
            <div className="block lg:hidden mx-auto">
              <img src="/assets/vectors/save-wallet.svg" alt="saved" />
            </div>

            <div className="flex flex-col gap-4">
              <label className="font-medium text-black-100">Username</label>
              <input
                type="email"
                className="border h-12 w-full rounded-lg py-3 px-6 text-sm"
                placeholder="Enter Your Email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setError("");
                }}
              />
            </div>
            <div className="flex relative flex-col w-full gap-4">
              <label className="font-medium text-black-100">Password</label>
              <input
                type={passwordShown ? "text" : "password"}
                className="border h-12 w-full rounded-lg py-3 px-6 text-sm"
                placeholder="Enter Your Password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setError("");
                }}
              />
              <img
                src={
                  passwordShown ? "/assets/eye.svg" : "/assets/eye-slash.svg"
                }
                alt="show/hide"
                className="absolute cursor-pointer bottom-12 right-4 w-6"
                onClick={() => setPasswordShown(!passwordShown)}
              />
              <Link to="" className="font-medium text-sm text-primary-blue">
                Forgot Password?
              </Link>
            </div>
            <button
              className="flex justify-center items-center md:bg-primary-blue bg-primary-gradient border-none py-4 px-10 rounded-full  text-white font-bold whitespace-nowrap"
              type="submit"
              onClick={handleSubmit}
            >
              Login
            </button>
            <span className="text-red-200 text-sm">{error && error}</span>
          </div>
        </div>
      </div>
    </>
  );
};
export default ConnectLinkkoAccount;
