import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useAccount, useSignMessage } from "wagmi";
import { doConnect, doSign } from "../redux/blockchain/blockchainActions";
import { useDispatch } from "react-redux";
import { Button } from "./common/Button";

const GatedPage = () => {
  function constructURL(routName, param1, param2, param3, param4) {
    let url = `/${routName}/?`;

    if (param1 !== "") {
      url += "telegramId=" + encodeURIComponent(param1) + "&";
    }

    if (param2 !== "") {
      url += "discordId=" + encodeURIComponent(param2) + "&";
    }

    if (param3 !== "") {
      url += "configurationId=" + encodeURIComponent(param3) + "&";
    }

    if (url.endsWith("&")) {
      url = url.slice(0, -1);
    }

    return url;
  }

  const [searchParams] = useSearchParams();
  const configurationId = searchParams.get("configurationId") || "";
  const telegramId = searchParams.get("telegramId") || "";
  const discordId = searchParams.get("discordId") || "";
  const [apiData, setApiData] = useState("");
  const [loading, setLoading] = useState(true);
  const [showMore, setShowMore] = useState(false);
  const urlforLinktoLinkko = constructURL(
    "login",
    telegramId,
    discordId,
    configurationId
  );
  const dispatch = useDispatch();
  const sign = useSignMessage();
  const account = useAccount();
  const navigate = useNavigate();
  const gatedContentPage = async () => {
    setLoading(true);
    const requestBody = {
      app_name: telegramId ? "telegram" : discordId ? "discord" : "notion",
      domain_name: configurationId,
    };
    await fetch(`${process.env.REACT_APP_BACKEND_URL}/gate_info`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(requestBody),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setApiData(data?.data || []);
      })
      .catch((err) => {
        console.log(err, "err");
      });
    setLoading(false);
  };

  useEffect(() => {
    gatedContentPage();
  }, []);
  return (
    <>
      {loading ? (
        ""
      ) : (
        <div className="min-h-screen flex justify-center bg-gray-primary relative">
          <div className=" mt-10 md:mt-4  md:px-16 py-10 md:py-11 ">
            <div className="absolute bottom-0 right-0 opacity-40 invisible md:visible">
              <img src="/assets/vectors/wallet-connect.svg" alt="bottom" />
            </div>
            <div className="flex justify-center flex-col items-center w-full h-full pb-4">
              <img src="/assets/lock.svg" alt="lock" />
              <div className="mt-4 flex flex-col items-center text-center">
                <span className="font-semibold text-2xl md:text-3xl text-black-100 md:block hidden">
                  Verify to access {apiData[0]?.appName}
                </span>
                <span className="font-semibold text-2xl md:text-3xl text-black-100 md:hidden block ">
                  Verify to access <br />
                  {apiData[0]?.appName}
                </span>
                <span className="text-gray-400 text-base text-center mt-4">
                  To access it, you need the below tokens:
                </span>
              </div>
              {apiData?.map((data) => (
                <div className="mt-0 md:mt-4 z-20 bg-white rounded-3xl md:w-150">
                  <div className="p-8">
                    <div className="flex flex-col md:flex-row gap-6 justify-center">
                      {data?.data?.imageUrl &&
                        data?.data?.imageUrl !== "None" && (
                          <div className="overflow-hidden mx-auto">
                            <img
                              src={`${data?.data?.imageUrl}` || ""}
                              alt="profile"
                              className="w-40 h-40 object-cover rounded-lg"
                            />
                          </div>
                        )}
                      <div className={``}>
                        <div
                          className={`${
                            data?.data?.imageUrl &&
                            data?.data?.imageUrl !== "None"
                              ? "justify-center md:justify-start"
                              : "justify-center"
                          } flex  gap-2`}
                        >
                          {data?.data?.chain === "polygon" ? (
                            <img src="/assets/poly-logo.svg" alt="polygon" />
                          ) : (
                            <img src="/assets/eth-logo.svg" alt="ethereum" />
                          )}

                          <span className="font-semibold text-base md:text-2xl text-black-100">
                            {`${data?.data?.name}`}
                          </span>
                        </div>
                        <div
                          className={`py-6 flex gap-4 ${
                            data?.data?.imageUrl &&
                            data?.data?.imageUrl !== "None"
                              ? "justify-center md:justify-start"
                              : "justify-center"
                          }`}
                        >
                          <div className="flex gap-2">
                            <span className="text-gray-100 font-medium text-sm">
                              Min. Number of Token:
                            </span>
                            <span className="text-sm text-black-100 font-semibold">
                              {`${data?.data?.requirements?.length}`}
                            </span>
                          </div>
                          <div className="flex gap-2">
                            <span className="text-gray-100 font-medium text-sm">
                              Token ID (s):
                            </span>
                            <span className="text-sm text-black-100 font-semibold">
                              {`${
                                data?.data?.requirements[0]?.ids?.length >= 1
                                  ? data?.data?.requirements[0]?.ids
                                  : "Any"
                              }`}
                            </span>
                          </div>
                        </div>

                        <div
                          className={`flex flex-wrap gap-4 ${
                            data?.data?.imageUrl &&
                            data?.data?.imageUrl !== "None"
                              ? "justify-center md:justify-start"
                              : "justify-center"
                          }`}
                        >
                          {data?.data?.requirements[0]?.attributes
                            ?.slice(0, 3)
                            ?.map((attributeObject, index) =>
                              Object.entries(attributeObject).map(
                                ([key, value]) => (
                                  <div
                                    key={index}
                                    className="border border-blue-100 w-fit p-3 rounded-2xl"
                                  >
                                    {" "}
                                    <span className="text-gray-100 text-sm">
                                      {key}:
                                    </span>
                                    <span className="text-primary-blue font-semibold text-sm pl-1">
                                      {typeof value === "object"
                                        ? JSON.stringify(value)
                                        : value}
                                    </span>
                                  </div>
                                )
                              )
                            )}
                        </div>
                      </div>
                    </div>
                    {data?.data?.requirements[0]?.attributes?.length > 3 && (
                      <div className="mt-4">
                        {!showMore ? (
                          <div
                            className="flex justify-center items-center gap-4 "
                            onClick={() => setShowMore(true)}
                          >
                            <span className="font-semibold text-sm text-primary-blue cursor-pointer">
                              See more properties required
                            </span>
                            <img src="/assets/arrow-down.svg" alt="arrow" />
                          </div>
                        ) : (
                          <div className="flex flex-wrap gap-4">
                            {data?.data?.requirements[0]?.attributes
                              ?.slice(3)
                              ?.map((attributeObject, index) =>
                                Object.entries(attributeObject).map(
                                  ([key, value]) => (
                                    <div
                                      key={index}
                                      className="border border-blue-100 w-fit p-3 rounded-2xl"
                                    >
                                      {" "}
                                      <span className="text-gray-100 text-sm">
                                        {key}:
                                      </span>
                                      <span className="text-primary-blue font-semibold text-sm pl-1">
                                        {typeof value === "object"
                                          ? JSON.stringify(value)
                                          : value}
                                      </span>
                                    </div>
                                  )
                                )
                              )}
                          </div>
                        )}
                      </div>
                    )}
                    <Link to={urlforLinktoLinkko}>
                      <button className="mt-4 flex justify-center items-center bg-blue-700 border-none w-full md:h-auto py-4 px-9 rounded-2xl text-white font-bold gap-2">
                        <img src="/assets/linkko-icon.svg" alt="logo" />
                        Connect with Linkko
                      </button>
                    </Link>
                    <Button />
                  </div>
                </div>
              ))}

              <div className="text-xs text-gray-100 my-5 ">
                Powered by Linkko 💙
              </div>
            </div>
          </div>
        </div>
      )}

      {account?.isConnected && dispatch(doSign(account, sign)) && <></>}

      {sign.isSuccess &&
        dispatch(
          doConnect(
            configurationId,
            telegramId,
            discordId,
            null,
            navigate,
            account,
            sign
          )
        ) && <></>}
    </>
  );
};

export default GatedPage;
